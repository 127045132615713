<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-menu-button color="primary"></ion-menu-button>
          <ion-back-button
            :default-href="`/resource/${resource.id}`"
          ></ion-back-button>
        </ion-buttons>
        <ion-title class="ion-text-center">
          <img
            src="assets/icon/logo.png"
            style="height: 40px"
            alt="xtenso|xsample"
          />
        </ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-content :fullscreen="true" class="ion-padding" v-if="resource.id">
      <h2>
        {{ resource.name }}
      </h2>

      <div
        v-for="(node, $index) in timeline"
        :class="`timeline-${node.side}`"
        :key="$index"
      >
        <h2 v-if="node.date" class="ion-text-center">{{ node.date }}</h2>

        <ion-card v-if="node.stats">
          <ion-card-header>
            <ion-card-title>Répartition des données journalières</ion-card-title>
            <ion-card-content>
              <div v-for="(n, a, i2) in node.stats" :key="i2"
                >
                <b>{{ a }}</b>:
                <i
                  >{{ Math.round((n / node.total.total) * 1000) / 10 }}% (x {{ n }})</i
                ></div>
            </ion-card-content>
          </ion-card-header>
        </ion-card>
<!--
        <div v-if="node.id" :class="'type-' + node.type">
          <i class="ion ion-checkmark"></i>
        </div>
        <div v-if="node.id">
            <div>{{ node.activity }}</div>
            <p>
              <small
                ><i class="ion ion-clock"></i>
                {{ formatDate(node.timestamp, "HH:mm") }}<br />
                <span v-for="(k, v) in node.data" :key="k"
                  ><b>{{ k }}:</b> {{ v }}
                </span>
              </small>
            </p>
          </div>
-->
      </div>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import { formatDate } from "@/services/filters";

import {
  IonBackButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/vue";

import { computed, defineComponent } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "@/store";

export default defineComponent({
  name: "ResourceStats",
  components: {
    IonButtons,
    IonContent,
    IonHeader,
    IonMenuButton,
    IonPage,
    IonTitle,
    IonToolbar,
    IonBackButton,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardTitle,
  },
  setup() {
    const store = useStore();
    const route = useRoute();

    const resource = computed(() => {
      return (
        store.state.resources.find((r) => r.id === route.params.id) || {
          id: "",
          name: "",
          activities: [],
        }
      );
    });
    const timeline = computed(() => {
      let lastDate = "";
      const timeline = [];
      let stats = {} as any;
      let total;
      let lastActivity = "";
      let side = "left";
      const logs = store.state.logs.filter(
        (l) => l.resourceId === route.params.id
      );
      for (let i = logs.length - 1; i > -1; i--) {
        const doc = Object.assign({}, store.state.logs[i]) as any;
        const date = new Date(doc.timestamp);
        const dateString =
          date.getFullYear() +
          "-" +
          (1 + date.getMonth()) +
          "-" +
          date.getDate();
        if (dateString !== lastDate) {
          stats = {};
          total = { total: 0 } as any;
          timeline.push({
            side: "left",
            date: dateString,
            stats: stats,
            total: total,
          });
          lastDate = dateString;
        }
        if (!stats.hasOwnProperty(doc.activity)) {
          stats[doc.activity] = 0;
        }
        stats[doc.activity]++;
        total.total++;
        if (doc.activity !== lastActivity) {
          side = side === "left" ? "right" : "left";
          lastActivity = doc.activity;
        }
        doc.side = side;
        timeline.push(doc);
      }
      return timeline;
    });

    return {
      resource,
      timeline,
      formatDate,
    };
  },
});
</script>
