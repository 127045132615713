
import { formatDate } from "@/services/filters";

import {
  IonBackButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/vue";

import { computed, defineComponent } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "@/store";

export default defineComponent({
  name: "ResourceStats",
  components: {
    IonButtons,
    IonContent,
    IonHeader,
    IonMenuButton,
    IonPage,
    IonTitle,
    IonToolbar,
    IonBackButton,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardTitle,
  },
  setup() {
    const store = useStore();
    const route = useRoute();

    const resource = computed(() => {
      return (
        store.state.resources.find((r) => r.id === route.params.id) || {
          id: "",
          name: "",
          activities: [],
        }
      );
    });
    const timeline = computed(() => {
      let lastDate = "";
      const timeline = [];
      let stats = {} as any;
      let total;
      let lastActivity = "";
      let side = "left";
      const logs = store.state.logs.filter(
        (l) => l.resourceId === route.params.id
      );
      for (let i = logs.length - 1; i > -1; i--) {
        const doc = Object.assign({}, store.state.logs[i]) as any;
        const date = new Date(doc.timestamp);
        const dateString =
          date.getFullYear() +
          "-" +
          (1 + date.getMonth()) +
          "-" +
          date.getDate();
        if (dateString !== lastDate) {
          stats = {};
          total = { total: 0 } as any;
          timeline.push({
            side: "left",
            date: dateString,
            stats: stats,
            total: total,
          });
          lastDate = dateString;
        }
        if (!stats.hasOwnProperty(doc.activity)) {
          stats[doc.activity] = 0;
        }
        stats[doc.activity]++;
        total.total++;
        if (doc.activity !== lastActivity) {
          side = side === "left" ? "right" : "left";
          lastActivity = doc.activity;
        }
        doc.side = side;
        timeline.push(doc);
      }
      return timeline;
    });

    return {
      resource,
      timeline,
      formatDate,
    };
  },
});
